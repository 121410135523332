@mixin flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-center {
  display: flex;
  align-items: center;
}

@mixin flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin line-clamp($clamp) {
  display: -webkit-box;
  -webkit-line-clamp: $clamp;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin reduce-text($width) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: $width;
}